import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
  Price, DishImage, DescriptionContainer
} from './ServicesElements';
import myData from '../TestList/data.json';
import myBeefData from '../TestList/beef_data.json';
import chickenData from "../TestList/chicken_data.json";
import mariscosData from "../TestList/mariscos_data.json";

import {OptionsContainer} from "../OptionBar/OptionElements";
import LayoutOptions from "../OptionBar";


const DishList = ({data}) => {
  return (
      <>
        {
            data && data.map((d, index) => {
              // var imageName = require(d.picture)
              return (
                  <ServicesCard key={index}>
                    <ServicesH2>{d.name}</ServicesH2>
                    <ServicesIcon src={d.picture}/>
                      <DescriptionContainer>
                    <ServicesP>{d.description}
                    </ServicesP>
                          </DescriptionContainer>
                    <Price>
                        {d.price}
                    </Price>
                  </ServicesCard>
              )
            })
        }
      </>
  )
}


const Dishes = ({data}) => {
  return (
      <>
        {
          data && data.map((d, index) => {
            return (
                <ServicesCard key={index}>
                  <ServicesH2>{d.name.first}</ServicesH2>
                  <ServicesIcon src={d.picture.thumbnail}/>
                  <ServicesP>{d.email}
                  </ServicesP>
                  <Price>
                    $10
                  </Price>
                </ServicesCard>
            )
          })
        }
      </>
  )
}

const Services = () => {
  const [restaurantData, setRestaurantData] = useState([])
  const [listData, setListData] = useState(myData)


  return (
    <ServicesContainer id='services'>
        {/* <LayoutOptions/> */}
        <ServicesH1 id='appetizers'>Botanas</ServicesH1>
        <ServicesWrapper>
            <DishList data={myData} />
        </ServicesWrapper>
      <ServicesH1 id='res'>Beef/Res</ServicesH1>
      <ServicesWrapper>
        <DishList data={myBeefData} />
      </ServicesWrapper>
      <ServicesH1 id='Pollo'>Chicken/Pollo</ServicesH1>
      <ServicesWrapper>
        <DishList data={chickenData} />
      </ServicesWrapper>
        <ServicesH1 id='mariscos'>Seafood/Mariscos</ServicesH1>
        <ServicesWrapper>
            <DishList data={mariscosData} />
        </ServicesWrapper>
    </ServicesContainer>

  );
};

export default Services;

