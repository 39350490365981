import React from 'react';
import {OptionLeft, OptionRight, OptionsContainer, OptionText, Divider} from './OptionElements'

const LayoutOptions = () => {

    return (
        <OptionsContainer>
            <OptionLeft>
                <OptionText to='/'>
                    Layout 1
                </OptionText>
            </OptionLeft>
            <Divider/>
            <OptionRight>
                <OptionText to='/twocol'>
                    Layout 2
                </OptionText>
            </OptionRight>
        </OptionsContainer>
    )
}

export default LayoutOptions