import React, {useState} from "react";
import MainContainer from "../MainContainer";
import {LargeContainer} from "./GridContainerElements"

const GridContainer = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };
    return (
            <LargeContainer>
                <MainContainer/>
            </LargeContainer>
    )
};

export default GridContainer