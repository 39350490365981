import React from 'react';
import './App.css';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ListViewPage from "./pages/listview";
import ResHome from "./pages";
import MenuPage from "./pages/menupage";
import CustomConfig from "./pages/custom";
import FormPage from "./pages/formpage"
import LayoutOptions from "./components/OptionBar";
import OrderForm from "./components/OrderForm";
import OutcomePage from "./pages/outcomes";
import BasicPage from "./pages/basic";


function App() {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<BasicPage />}  />
                <Route path='/listview' element={<ListViewPage/>} />
                <Route path='/test' element={<MenuPage/>} />
                <Route path='/twocol' element={<CustomConfig/>} />
                <Route path='/form' element={<FormPage/>} />
                <Route path='/options' element={<LayoutOptions/>} />
                <Route path='/orderform' element={<OrderForm/>} />
                <Route path='/outcomes' element={<OutcomePage/>} />
                <Route path='/complete' element={<ResHome/>} />




            </Routes>
        </Router>
    );
}

export default App;
