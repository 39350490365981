import React from "react";
import {Content, Container, Column, Border} from './ContainerElements';
import myBeefData from "../TestList/beef_data.json";
import {Price, ServicesH2, ServicesIcon} from "../CustomServices/ServicesElements";

const PicList = ({data}) => {
  return (
      <>
        {
            data && data.map((d, index) => {
              // var imageName = require(d.picture)
              return (
                  <>
                    <ServicesH2>{d.name}</ServicesH2>
                    <ServicesIcon src={d.picture}/>
                    <Price>
                        {d.price}
                    </Price>
                  </>
              )
            })
        }
      </>
  )
}


const MainContainer = () => {

    return (

            <Container>
                <Column>
                    <Content><PicList data={myBeefData}></PicList></Content>
                </Column>
                <Column>
                    <Content><PicList data={myBeefData}></PicList></Content>

                </Column>

            </Container>
    )
};

export default MainContainer