import React, {useState} from "react";
import ResFooter from "../components/CustomFooter";
import Sidebar from "../components/CustomSidebar";
import Navbar from "../components/CustomNavbar";
import Outcomes from "../components/Outcomes"
import GridContainer from "../components/GridContainer"

function OutcomesPage() {
    const [isOpen, setIsOpen] = useState(false);



    const toggle = () => {
        setIsOpen(!isOpen);
    };
    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} pageName={"Outcome Maker"}/>
            <Outcomes />
            <ResFooter />
        </>
    );
}

export default OutcomesPage;