import React, {useState} from "react";
import ResFooter from "../components/CustomFooter";
import Sidebar from "../components/CustomSidebar";
import Navbar from "../components/CustomNavbar";
import ListView from "../components/ListView"

function ListViewPage() {
    const [isOpen, setIsOpen] = useState(false);


    const toggle = () => {
        setIsOpen(!isOpen);
    };
    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />
            <ListView />
            <ResFooter />
        </>
    );
}

export default ListViewPage;