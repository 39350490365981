import styled from 'styled-components';


export const LargeContainer = styled.div`
    padding: 100px;
    
    @media screen and (max-width: 1000px) {
    padding: 100px 10px 100px 10px;
  }
`;


export const ServicesContainer = styled.div`
  background: lightsteelblue;
  padding-top: 70px;
  padding-bottom: 70px;

  margin-bottom: 5px;
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
  display: flex; /* Use flexbox */
  justify-content: center; /* Center the cards horizontally */
  align-items: center; /* Optionally center the cards vertically */
  flex-direction: column; /* Ensure children are stacked */
`;

export const ServicesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%; /* Ensure the wrapper takes full width */
`;

export const ServicesCard = styled.div.attrs(props => ({
    size: props.size || "1em",
}))`
  background: #fff;
  overflow: auto;
  display: block;
  border-radius: 10px;
  width: 85%; /* Make sure the card takes 85% of the parent width */
  max-width: 900px; /* Optionally set a max-width if you want to constrain it */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  transform: scale(.98);
  
  &:hover {
    transform: scale(1.001);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

export const ServicesIcon = styled.img`
  max-width: 95%;

  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const DishImage = styled.div`

  background-image: url(${props => props.url});
  max-height: 100%;
  max-width: 100%;

  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const ServicesH1 = styled.h1`
  font-size: 2.5rem;
  color: black;
  margin-bottom: 5px;
  padding: 5px; 
  text-align: center;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const DescriptionContainer = styled.div`
    max-height: 75px;
    overflow: auto;
    padding-top: 5px;
    padding-bottom: 5px;
`;

export const ServicesH2 = styled.h2`
  font-size: 1.25rem;
  margin-bottom: 10px;
  text-align: center;
  padding-top: 5px;
  font-weight: 900;
`;

export const ServicesP = styled.p`
  font-size: 1rem;
  text-align: center;
  padding: 5px;
  font-weight: 500;

`;

export const Price = styled.p`
  color: darkgreen;
  display: block;
  text-align: center;
  clear: both;
  font-weight: lighter;

`;

export const Seperator = styled.hr`

    width:50%;
    text-align: center;
    height:1px;
    background-color:black;
    border-style: solid;
    margin: auto;
        `;
