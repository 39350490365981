import React, {useState, useEffect} from "react";
import FormComponent from '../components/FormComponent';
import {MenuContainer, Content, DishImage} from '../components/Menu/MenuElements';
import Sidebar from "../components/CustomSidebar";
import Navbar from "../components/CustomNavbar";

function FormPage() {
      const [isOpen, setIsOpen] = useState(false);
  const [isMediaView, setMediaView] = useState(true);

    const toggle = () => {
        console.log("clicked menu is ", isOpen)
    setIsOpen(!isOpen);
  };

    const mediaView = () => {
    setMediaView(!isMediaView);
  };
    return (

        <Content>
            <FormComponent>
            </FormComponent>
        </Content>
    )
}

export default FormPage