import React, {useState} from "react";
import {Content,Container, Column, Border}  from "../MainContainer/ContainerElements";
import {LargeContainer} from "../GridContainer/GridContainerElements"
import outcomeTable from "./outcomeTable.json";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
  Price, DishImage, DescriptionContainer
} from '../CustomServices/ServicesElements';
import Services from "../CustomServices";
import myData from "../TestList/data.json";

const PicList = ({data}) => {
  return (
      <>
        {
            data && data.map((d, index) => {
              // var imageName = require(d.picture)
              return (
                  <>
                                    <ServicesCard key={index}>

                    <ServicesH2>{d.name}</ServicesH2>
                    <ServicesIcon src={d.picture}/>
                    <Price>
                        {d.price}
                    </Price>
                                        </ServicesCard>

                  </>
              )
            })
        }
      </>
  )
}


const MainContainer = () => {

    return (

            <ServicesContainer>

                <ServicesWrapper>
                    <PicList data={outcomeTable}></PicList>
                </ServicesWrapper>


            </ServicesContainer>
    )
};

const Outcomes = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };
    return (
            <LargeContainer>
                <MainContainer/>
            </LargeContainer>
    )
};

export default Outcomes