import React, {useEffect, useState} from 'react';
import {
    DishContainer,
    DishDescription,
    DishTitle,
    MenuContainer,
    MenuH1,
    MenuWrapper,
    Price,
    Seperator
} from './ListViewElements'
import {ServicesCard, ServicesH2, ServicesIcon, ServicesP} from "../CustomServices/ServicesElements";
import myData from "../TestList/data.json";
import beefData from "../TestList/beef_data.json";
import chickenData from "../TestList/chicken_data.json";
import mariscosData from "../TestList/mariscos_data.json";

import LayoutOptions from "../OptionBar";


const Dish = ({data}) => {
  return (
      <>
        {
            data && data.map((d, index) => {
              return (
                  <DishContainer key={index}>
                    <DishTitle>{d.name}</DishTitle>
                    <DishDescription>{d.description}
                        <Price>
                            {d.price}
                        </Price>
                    </DishDescription>

                  </DishContainer>
              )
            })
        }
      </>
  )
}

const OldListView = () => {

    return (
        <MenuContainer>
            <MenuH1>
                Appetizers
            </MenuH1>
            <MenuWrapper>
                <DishTitle>
                    Queso Fundido
                </DishTitle>
                <DishDescription>
                    Melted cheese served with tortillas. Add protein (optional)
                    <Price>
                        $2.50
                    </Price>
                </DishDescription>
                <DishTitle>
                    Guacamole
                </DishTitle>
                <DishDescription>
                    Delicious guacamole
                    <Price>
                        $5.50
                    </Price>
                </DishDescription>
                <DishTitle>
                    Elotes Callejeros
                </DishTitle>
                <DishDescription>
                    Delicious corn
                    <Price>
                        $3.50
                    </Price>
                </DishDescription>


            </MenuWrapper>

        </MenuContainer>
    )
};

const ListView = () => {
    const [listData, setListData] = useState(myData)


    return (
        <MenuContainer>
            {/* <LayoutOptions/> */}
            <MenuH1 id='appetizers'>
                Appetizers/Botanas
            </MenuH1>
            <MenuWrapper>
                <Dish data={listData} />
            </MenuWrapper>
            <Seperator/>
            <MenuH1 id='res'>
                Beef/Res
            </MenuH1>
            <MenuWrapper>
                <Dish data={beefData} />
            </MenuWrapper>
            <Seperator/>
            <MenuH1 id='Pollo'>
                Chicken/Pollo
            </MenuH1>
            <MenuWrapper>
                <Dish data={chickenData} />
            </MenuWrapper>
            <Seperator/>
            <MenuH1 id='mariscos'>
                Seafood/Mariscos
            </MenuH1>
            <MenuWrapper>
                <Dish data={mariscosData} />
            </MenuWrapper>

        </MenuContainer>
    )
};

export default ListView