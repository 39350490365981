import styled from 'styled-components';

export const ServicesContainer = styled.div`
  background: lightsteelblue;
  padding-top: 70px;
  margin-bottom: 5px;
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;


`;

export const ServicesWrapper = styled.div`
  padding-bottom: 20px;
  white-space: nowrap;
  overflow: auto;
  margin-left: auto;
  margin-right: auto;

`;

export const ServicesCard = styled.div.attrs(props => ({
    // we can define static props

    // or we can define dynamic ones
    size: props.size || "1em",
}))`
  background: #fff;
  overflow: auto;
  display: inline-block;
  vertical-align: middle;

  white-space: normal;
  position: relative;
  border-radius: 10px;
  max-height: 100%;
  width: 85%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  transform: scale(.98);

  
  @media screen and (max-width: 300px) {
  }
  &:hover {
    transform: scale(1.001);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

export const ServicesIcon = styled.img`
  max-width: 95%;

  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const DishImage = styled.div`

  background-image: url(${props => props.url});
  max-height: 100%;
  max-width: 100%;

  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const ServicesH1 = styled.h1`
  font-size: 2.5rem;
  color: black;
  margin-bottom: 5px;
  padding: 5px; 
  text-align: center;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const DescriptionContainer = styled.div`
    max-height: 75px;
    overflow: auto;
    padding-top: 5px;
    padding-bottom: 5px;
`;

export const ServicesH2 = styled.h2`
  font-size: 1.25rem;
  margin-bottom: 10px;
  text-align: center;
  padding-top: 5px;
  font-weight: 900;
`;

export const ServicesP = styled.p`
  font-size: 1rem;
  text-align: center;
  padding: 5px;
  font-weight: 500;

`;

export const Price = styled.p`
  color: darkgreen;
  display: block;
  text-align: center;
  clear: both;
  font-weight: lighter;

`;