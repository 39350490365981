import styled from 'styled-components';

export const Column = styled.div`
    background: white;
    display: block;
    text-align: center;




`;

export const Content = styled.p`

`;

export const ContentContainer = styled.div``;

export const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: calc(100vh);
    column-gap: 20px;
    
    @media screen and (max-width: 1000px) {
        column-gap: 5px;
      }
`;


export const Border = styled.div`
    border-right: 5px solid black;

`;