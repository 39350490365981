import React, {useState, useEffect} from "react";
import ResFooter from "../components/CustomFooter";
import Sidebar from "../components/CustomSidebar";
import Navbar from "../components/CustomNavbar";
import Services from "../components/CustomServices";



function ResHome() {
  const [isOpen, setIsOpen] = useState(false);
  const [isMediaView, setMediaView] = useState(true);

    const toggle = () => {
        console.log("clicked menu is ", isOpen)
    setIsOpen(!isOpen);
  };

    const mediaView = () => {
    setMediaView(!isMediaView);
  };

    useEffect(() => {
        const componentDidMount = () => {
            navigator.geolocation.getCurrentPosition(function (position) {
                console.log("Latitude is :", position.coords.latitude);
                console.log("Longitude is :", position.coords.longitude);
                console.log(position.coords.latitude, position.coords.longitude);
            });
        }
        componentDidMount();


    });

  return (
    <>
        <Sidebar isOpen={isOpen} toggle={toggle} mediaView={mediaView}/>
        <Navbar toggle={toggle} mediaView={mediaView}/>
        <Services />
        <ResFooter />
    </>
  );
}

export default ResHome;