import React, {useState} from "react";
import ResFooter from "../components/CustomFooter";
import Sidebar from "../components/CustomSidebar";
import Navbar from "../components/CustomNavbar";
import {
    Price,
    ServicesCard,
    ServicesContainer,
    ServicesH2, ServicesIcon,
    ServicesWrapper,
    LargeContainer,
    Seperator,
    ServicesP,
    ServicesH1
} from "../components/Basic/BasicElements";
import basicTable from "../components/Basic/BasicTable.json";


const BusinessDetails = ({data}) => {
    return (
        <>
            {
                data && data.map((d, index) => {
                    // var imageName = require(d.picture)
                    return (
                        <>
                            <ServicesCard key={index}>

                                <ServicesH1>{d.BusinessName}</ServicesH1>
                                <ServicesIcon src={d.BusinessPicture}/>
                                <br/>
                                <Seperator/>
                                <br/>
                                <ServicesH2>Address:</ServicesH2>
                                <ServicesP>{d.Address}</ServicesP>
                                <br/>
                                <Seperator/>
                                <br/>
                                <ServicesH2>Hours:</ServicesH2>
                                <ServicesP>Monday : {d.Hours.Monday}</ServicesP>
                                <ServicesP>Tuesday : {d.Hours.Tuesday}</ServicesP>
                                <ServicesP>Wednesday : {d.Hours.Wednesday}</ServicesP>
                                <ServicesP>Thursday : {d.Hours.Thursday}</ServicesP>
                                <ServicesP>Friday : {d.Hours.Friday}</ServicesP>
                                <ServicesP>Saturday : {d.Hours.Saturday}</ServicesP>
                                <ServicesP>Sunday : {d.Hours.Sunday}</ServicesP>

                                <br/>
                                <Seperator/>
                                <br/>
                                <ServicesH2>Contact:</ServicesH2>
                                <ServicesP>{d.Contact}</ServicesP>


                            </ServicesCard>

                        </>
                    )
                })
            }
        </>
    )
}

const MainContainer = () => {

    return (
        <LargeContainer>
            <ServicesContainer>
                <ServicesWrapper>
                    <BusinessDetails data={basicTable}></BusinessDetails>
                </ServicesWrapper>
            </ServicesContainer>
        </LargeContainer>
    )
};

function BasicPage() {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle}/>
            <Navbar toggle={toggle} pageName={"Your Business Name"}/>
            <MainContainer/>
            <ResFooter/>
        </>
    );
}

export default BasicPage;